// extracted by mini-css-extract-plugin
export var CarouselCard1 = "dashboard-module--CarouselCard1--bdca7";
export var CarouselCard2 = "dashboard-module--CarouselCard2--80b6f";
export var DashListBeds = "dashboard-module--DashListBeds--61424";
export var DashListSilverBeds = "dashboard-module--DashListSilverBeds--0100d";
export var Link = "dashboard-module--Link--0556e";
export var Mapdesign = "dashboard-module--Mapdesign--c0769";
export var MappageClassDesign = "dashboard-module--MappageClassDesign--1a9cf";
export var Mapresultpagecontainer = "dashboard-module--Mapresultpagecontainer--a0031";
export var beds = "dashboard-module--beds--90ed5";
export var box = "dashboard-module--box--dd6d1";
export var card = "dashboard-module--card--48d65";
export var cardimage = "dashboard-module--cardimage--e4c11";
export var cardimage2 = "dashboard-module--cardimage2--ab5d2";
export var cardname = "dashboard-module--cardname--b4e2f";
export var cardtext = "dashboard-module--cardtext--6d678";
export var carouselCard = "dashboard-module--carouselCard--6d604";
export var carouselImage = "dashboard-module--carouselImage--1257e";
export var carouselRes = "dashboard-module--carouselRes--8ec45";
export var carouselRes2 = "dashboard-module--carouselRes2--1cca3";
export var circle = "dashboard-module--circle--50232";
export var circle1 = "dashboard-module--circle1--480bd";
export var dashboardActive = "dashboard-module--dashboardActive--a44e4";
export var dashboardCard = "dashboard-module--dashboardCard--7496a";
export var dashboardContainer = "dashboard-module--dashboardContainer--ccd71";
export var dashboardModuleCarouselCard1 = "dashboard-module--dashboard-module--CarouselCard1--d00fe";
export var dashhead = "dashboard-module--dashhead--fd6c1";
export var dashlistprice = "dashboard-module--dashlistprice--a1906";
export var desktopapp = "dashboard-module--desktopapp--4770c";
export var entrance = "dashboard-module--entrance--2ffc5";
export var entrance2 = "dashboard-module--entrance2--24e5c";
export var entrance3 = "dashboard-module--entrance3--9b18d";
export var entrance4 = "dashboard-module--entrance4--64e01";
export var exclusiv = "dashboard-module--exclusiv--6e1c6";
export var favorite = "dashboard-module--favorite--02a73";
export var favorite1 = "dashboard-module--favorite1--22e74";
export var featuredProperty = "dashboard-module--featuredProperty--94b2e";
export var hexagon = "dashboard-module--hexagon--280dd";
export var hexagonContain = "dashboard-module--hexagonContain--6691b";
export var hexagonInner = "dashboard-module--hexagonInner--69fbf";
export var hexagonInnerText1 = "dashboard-module--hexagonInnerText1--9a7b4";
export var hexagonInnerText2 = "dashboard-module--hexagonInnerText2--d81b9";
export var home = "dashboard-module--home--9a1ec";
export var homePrice = "dashboard-module--homePrice--eaf87";
export var house1 = "dashboard-module--house1--33f46";
export var house3 = "dashboard-module--house3--c5495";
export var houses = "dashboard-module--houses--95c75";
export var innerImageContent = "dashboard-module--innerImageContent--50ed0";
export var innerbutton1 = "dashboard-module--innerbutton1--97124";
export var joinbutton = "dashboard-module--joinbutton--b9a09";
export var link = "dashboard-module--link--da05c";
export var listing = "dashboard-module--listing--f8bb1";
export var means = "dashboard-module--means--254dd";
export var memberbutton = "dashboard-module--memberbutton--dccad";
export var mia = "dashboard-module--mia--e5f99";
export var mobileView = "dashboard-module--mobileView--bd016";
export var navbar = "dashboard-module--navbar--b3008";
export var noah = "dashboard-module--noah--b2540";
export var property = "dashboard-module--property--9bfe6";
export var real = "dashboard-module--real--0b248";
export var review = "dashboard-module--review--e509f";
export var row = "dashboard-module--row--56eea";
export var search = "dashboard-module--search--84ddb";
export var search1 = "dashboard-module--search1--dbb3f";
export var search2 = "dashboard-module--search2--5b3b5";
export var searchIcon = "dashboard-module--searchIcon--6a46c";
export var searchprice = "dashboard-module--searchprice--930ca";
export var testimonial = "dashboard-module--testimonial--0e1d4";
export var text = "dashboard-module--text--7d883";