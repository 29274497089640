import React from 'react'
import { Row, Col } from 'react-bootstrap'
import * as style2 from "../../pages/buyerdetaipages/buyerdetail.module.css"
import Skeleton from 'react-loading-skeleton'
import * as styled from "../../pages/buyers/buyer.module.css"

const GallerySkeleton = (props) => {
    return (
        <>
            {props.purpose == "recently" &&
                <div><Row className={style2.recenttourdetail1} >
                    <Col lg="5"><Skeleton className={style2.recenttourImage} /></Col>
                    <Col lg="7"><Skeleton style={{ width: "93%", height: "25px", marginTop: "22px" }} /><Skeleton style={{ width: "50%", height: "25px", marginTop: "22px" }} /></Col>
                </Row></div>}

                {props.purpose == "gallery" &&
                    <div className="col-md-6" id={styled.BuyerDetailSavedPropertyImage}>
                    <div className={styled.sanfranImg}><Skeleton style={{ height: "116px",width:"144px" }}/>
                    <Skeleton style={{ height: "16px" ,marginTop:"20px"}}/>
                    <Skeleton style={{ height: "16px" }}/></div>
                    </div>
                }
                </>
    )
}

export default GallerySkeleton