import React, { useEffect} from "react";
import { Card } from "react-bootstrap";
import * as styles from "../../pages/seller/sellerProp.module.css"
import * as style2 from "../buyerdetaipages/buyerdetail.module.css"
import { Row, Col } from "react-bootstrap"
import * as styled from "../buyers/buyer.module.css"
import moment from "moment";
import { useSelector } from "react-redux";


function SellerManageOffer(props) {
    const { item, property, toggleShow, propertyStatus } = props
    const visible= true
    const userDetails = useSelector(state => state.user.userDetail);
    // const _id = item._id;

    // const [doc1, setDoc1] = useState([])
    useEffect(() => {
        for (let i = 0; i < item.proof_of_identity.length; i++) {
            // setDoc1(item.proof_of_identity[i])
        }
    }, [])
    return (
        <div className="editback">
            {visible ? (
                <Card className="editCard" style={{padding: "11px"}}>
                    <div className="position-relative">
                        <i className="fa fa-close" style={{ fontSize: "25px", color: "rgb(181 172 172)", position: "absolute", right: "8px" }}
                           aria-label="close" onClick={toggleShow} onKeyPress={toggleShow} role="button" tabIndex="0"></i>
                    </div>
                    <div className="manageoffer" style={{ marginLeft: "20px", marginRight: "20px" }} ><div>
                        <div style={{ flexBasis: "312px", fontSize: "14px" }}>{item.user_id.name.charAt(0).toUpperCase() + item.user_id.name.slice(1)}</div>
                        <div style={{ paddingTop: "2px", fontSize: "11px", }}>{moment.utc(item.createdAt).format('ddd, MMM DD HH:mm A')}</div>
                    </div>
                        <hr></hr>
                    </div>
                    <div className="fullcontainer" id={styles.sellerlistingcontainer}>
                        <p style={{ fontFamily: "DejaVuSansBold", marginTop: "10px" }}> Active Offers</p>
                        <div className="containerbath" >
                            <div className={style2.propdetail} style={{ width: "100%" }}>
                                <img src={property.property_id.thumbnail_image} alt="home1" style={{ height: "84px", width: "30%" }} />
                                <div style={{ marginLeft: "10px", fontSize: "12px", width: "70%" }}>
                                    <div className="d-flex" style={{ alignItems: "center" }}>
                                        <i className="fa fa-circle" style={{ color: propertyStatus === 1 || propertyStatus === 2 ? "#03B45B" :propertyStatus === 3 ? "#1b1361": propertyStatus === 4 ? "yellow" : "red", fontSize: "15px" }} ></i>
                                        <p className={styles.listingStaus}>{propertyStatus === 1 ? "Active" : propertyStatus === 2 ? "Active Contingent" : propertyStatus === 3 ? "Exclusive" : propertyStatus === 4 ? "Pending" : propertyStatus === 5 ? "Sold" : propertyStatus === 6 ? "Expired" : propertyStatus === 7 ? "Cancelled" : "Withdrawn"}</p>
                                    </div>
                                    <div>
                                        <div className={styles.listingAddress}>{property.property_id.address}</div>
                                        <div className={styles.listingAgent}>Agent</div>
                                        <div className={styles.listingName}>{userDetails.name.charAt(0).toUpperCase() + userDetails.name.slice(1)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="containerbath">
                            <p id={styles.listDocs}>List of Documents</p>
                            <div>
                                <Row className={styled.searchCriet} style={{ gap: "0.5rem" }}>
                                    <Col style={{ padding: "0", fontFamily: "DejaVuSansBold" }} id={styles.listDocs}>Buyer Representation Agreement</Col>
                                    {item.proof_of_identity.map((data, i) => (
                                        <div className="d-flex" id={styles.listingproofId} style={{ padding: "0" }}>
                                            <Col style={{ ontFamily: "DejaVuSansBold", textAlign: "left", }} id={styles.listDocs}>{data.split('-').pop()}</Col>
                                            <Col style={{ textAlign: "right" }} id={styles.listingView}><a href={`${process.env.API_IMAGE_URL + data}`} target="_blank" rel="noreferrer">view</a></Col></div>
                                    ))}
                                </Row></div>
                            <Row className={styled.searchCriet} style={{ gap: "0.5rem" }}>
                                <Col style={{ padding: "0", fontFamily: "DejaVuSansBold" }} id={styles.listDocs}>Proof of Funds</Col>
                                {item.proof_of_funds.map((data) => (<div className="d-flex" style={{ padding: "0" }}>
                                    <Col style={{ ontFamily: "DejaVuSansBold", textAlign: "left", }} id={styles.listDocs}>{data.split('-').pop()}</Col>
                                    <Col style={{ textAlign: "right" }} id={styles.listingView}><a href={`${process.env.API_IMAGE_URL + data}`} target="_blank" rel="noreferrer">view</a></Col></div>
                                ))}
                            </Row>
                            <Row className={styled.searchCriet} style={{ gap: "0.5rem" }}>
                                <Col style={{ padding: "0", fontFamily: "DejaVuSansBold" }} id={styles.listDocs}>Mortgage Approval Letter</Col>
                                {item.mortgage_approval_letter.map((data) => (
                                    <div className="d-flex" style={{ padding: "0" }}>
                                        <Col style={{ textAlign: "left", }} id={styles.listDocs}>{data.split('-').pop()}</Col>
                                        <Col style={{ textAlign: "right" }} id={styles.listingView}><a href={`${process.env.API_IMAGE_URL + data}`} target="_blank" rel="noreferrer">view</a></Col></div>
                                ))}
                            </Row>
                            <Row className={styled.searchCriet} style={{ gap: "0.5rem" }}>
                                <Col style={{ padding: "0", fontFamily: "DejaVuSansBold" }} id={styles.listDocs}>Intro Letter</Col>
                                {item.intro_letter.map((data) => (
                                    <div className="d-flex" style={{ padding: "0" }}>
                                        <Col style={{ textAlign: "left", }} id={styles.listDocs}>{data.split('-').pop()}</Col>
                                        <Col style={{ textAlign: "right" }} id={styles.listingView}><a href={`${process.env.API_IMAGE_URL + data}`} target="_blank" rel="noreferrer">view</a></Col></div>
                                ))}
                            </Row>
                        </div>
                        <div className={styled.searchCriettext}>
                            <Row className={styled.searchCriet} style={{ gap: "0.5rem" }}>
                                <Col style={{ padding: "0" }} id={styles.listDocs}>Current Offer Price</Col>
                                <Col className={styles.ListPrice} style={{ fontFamily: "DejaVuSansBold", textAlign: "right", fontSize: "15px" }}>${item.current_offer.$numberDecimal.replace(/(.)(?=(\d{3})+$)/g, "$1,")}</Col>
                            </Row>
                            <Row className={styled.searchCriet} style={{ gap: "0.5rem" }}>
                                <Col style={{ padding: "0" }} id={styles.listDocs}>Deposit</Col>
                                <Col className={styles.ListPrice} style={{ fontFamily: "DejaVuSansBold", textAlign: "right", fontSize: "15px" }}>{item.deposits.replace(/(.)(?=(\d{3})+$)/g, "$1,")} %</Col>
                            </Row>
                            <Row className={styled.searchCriet} style={{ gap: "0.5rem" }}>
                                <Col style={{ padding: "0" }} id={styles.listDocs}>Loan Amount</Col>
                                <Col className={styles.ListPrice} style={{ fontFamily: "DejaVuSansBold", textAlign: "right", fontSize: "15px" }}>${item.loan_amount.$numberDecimal.replace(/(.)(?=(\d{3})+$)/g, "$1,")}</Col>
                            </Row>
                            <Row className={styled.searchCriet} style={{ gap: "0.5rem" }}>
                                <Col style={{ padding: "0" }} id={styles.listDocs}>POF</Col>
                                <Col className={styles.ListPrice} style={{ fontFamily: "DejaVuSansBold", textAlign: "right", fontSize: "15px" }}>{item.p_o_f}</Col>
                            </Row>
                            <Row className={styled.searchCriet} style={{ gap: "0.5rem" }}>
                                <Col style={{ padding: "0" }} id={styles.listDocs}>Contingencies</Col>
                                <Col className={styles.ListPrice} style={{ fontFamily: "DejaVuSansBold", textAlign: "right", fontSize: "15px" }}>{item.contingencies}</Col>
                            </Row>
                            <Row className={styled.searchCriet} style={{ gap: "0.5rem" }}>
                                <Col style={{ padding: "0" }}>
                                    <div style={{ padding: "0" }} id={styles.listDocs}>Signed Disclosure</div>
                                    {/* <h6 className={styles.ListPrice} style={{ fontFamily: "DejaVuSansBold", paddingLeft: "0", fontSize: "10px", }}>3 buyers have viewed disclosure</h6> */}
                                </Col>
                                <Col style={{ fontFamily: "DejaVuSansBold", textAlign: "right", display: "flex", justifyContent: "space-between", flexWrap: "wrap", paddingLeft: 0 }}>
                                    <div className="sellerManger">{item.signed_disclosure.split('-').pop()}</div>
                                    <div ><a href={`${process.env.API_IMAGE_URL + item.signed_disclosure}`} target="_blank" rel="noreferrer">view</a></div></Col>
                            </Row>
                        </div>
                    </div>
                </Card>
            ) : null}
        </div>
    )
}
export default SellerManageOffer