import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Row, Col, Button } from 'react-bootstrap';
import Layout from '../../components/Layout';
import { claimPropertyList, getOffers, recentlySold } from "../../components/Api/ListingApi";
import SkeletonHorizondalCard from "../../components/card/skeletonHorizondalCard";
// import newBrokaragelogo from "../../../static/images/newBrokaragelogo.png"
import MyListDetail from './MyListDetail';
import { Link } from "gatsby";
import "./MyList.css"
import Pagination from '../../components/Pagination';
import Seo from "../../components/seo";
import keywelogocard from "../../../static/images/keywelogocard.png";
import mls2Image from "../../../static/images/mls2Image.png";
import { useSelector } from 'react-redux';
import { Mylistdetails, formatPrice, getListingStatus, getStatusColor } from '../../common/GenericFunction';
import filterIcon from '../../../static/images/sortup.png';
import sortdown from '../../../static/images/sortdown.png';
import Moment from "moment-timezone";
import coldwell from "../../../static/images/coldwell.png"
import intero from "../../../static/images/intero.png"
import kellerwilliams from "../../../static/images/kellerwilliams.png"
import compass from "../../../static/images/compass.png"
import theagency from "../../../static/images/theagency.png"


var currentPage = 1;
export default function MyList() {
    const [loader, setLoader] = useState(false);
    const [myListData, setMyListData] = useState([]);
    const [isMyList, setIsMyList] = useState(false);
    const [myListDetails, setMyListDetails] = useState({});
    const [anotherProps, setAnotherProps] = useState(false);
    const [offer, setOffer] = useState({});
    const [recentSold, setRecentSold] = useState([]);
    const [dataSort, setDataSort] = useState(-1)
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [pagecount, setpagecount] = useState(0);
    const [paginationCount, setpaginationCount] = useState(0);
    const [selectedPage, setSelectedPage] = useState(0);
    const [propertyStatus, setPropertyStatus] = useState("")
    const [verifyData, setVerifyData] = useState(false)
    const array = ["1", "2", "3", "4"]
    //redux value get
    const userDetails = useSelector(state => state.user.userDetail);
    const userlocation = useSelector(state => state.user.userLocation);
    //usEffect declare //
    useEffect(() => {
        async function fetchData() {
            setLoader(true);
            await myList(propertyStatus, currentPage)
            setLoader(false);
        } fetchData()
        Mylistdetails("My List", userDetails, userlocation)
    }, [dataSort]);

    // my list api integration //
    const myList = async (status, filterPage) => {
        setpaginationCount(filterPage)
        let userId = localStorage.getItem('userId')
        let getParams = {
            status: 'Approve',
            id: userId,
            pagesize: 20,
            page: filterPage,
            sort_field: "_id",
            sort_value: dataSort,
            listing_status: status
        }
        setLoader(true);
        await claimPropertyList(getParams).then((res) => {
            setLoader(false);
            if (res.status === 200) {
                setpagecount(res.data.Paging.total_page);
                setSelectedPage(res.data.Paging.current_page - 1);
                setMyListData(res.data.data)
            }
        })
    }

    const handleReload = () => {
        myList(propertyStatus, currentPage)
    }

    async function handlePageClick(event) {
        currentPage = event.selected + 1;
        setIsMyList(false)
        await myList(propertyStatus, currentPage);
    };

    const hanldeImage = (img) => {
        let data = img !== null && img !== undefined && img.split('/')[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }
    const OpnAnotherProps = () => {
        setAnotherProps(!anotherProps)
    }
    useEffect(() => {
        async function fetchData() {
            setMyListDetails(myListData[index])
        } fetchData()
    }, [myListData])

    const offerProperty = async (data) => {
        let id = (data !== undefined ? data.property._id : null);
        await getOffers(id).then((res) => {
            if (res.status === 200) {

                setOffer(res.data.data)
            }
        })
    }
    //recently sold api inegration
    const recentlySoldComparable = async (data) => {
        let id = data.property._id
        let getParams = {
            longitude: data?.property?.location?.coordinates !== undefined ? data.property.location.coordinates[0] : data.property.property_latitude,
            latitude: data?.property?.location?.coordinates !== undefined ? data.property.location.coordinates[1] : data.property.property_longitude,
        }
        // setLoader(true)
        await recentlySold(id, getParams).then(res => {
            if (res.status === 200) {
                setVerifyData(true)
                setRecentSold(res.data.data)
                let data = [...res.data.data]
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    // all api data send to details page
    const handleClick = async (data, i) => {
        setMyListDetails(data)
        await offerProperty(data);
        recentlySoldComparable(data)
        setOpen(true);
        setIndex(i);
        setIsActive(true);
        setIsMyList(true);
    }

    var dataValue = ""
    var shouldUseCrossOrigin = false
    const handleImage = (imgValue, address) => {

        if (imgValue == undefined || imgValue == "") {
            shouldUseCrossOrigin = false
            dataValue = `https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${address ? address.replaceAll("#", "") : ""}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`

            return dataValue
        }
        else {
            // let data = imgValue !== null && imgValue.split('/')
            if (imgValue !== null && imgValue.split('/')[2] == "gokeywe.com") {
                shouldUseCrossOrigin = true
                dataValue = imgValue
                return dataValue
            }
            if (imgValue !== null && imgValue.split('/')[0] == "uploads") {
                shouldUseCrossOrigin = true
                dataValue = process.env.API_IMAGE_URL + imgValue
                return dataValue
            }
            else {
                dataValue = imgValue
                shouldUseCrossOrigin = false
                return dataValue
            }
        }
    }

    return (
        <Layout>
            <Seo title="myList" />
            <div>
                <div className='searchBuy'>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className='mt-0' style={{ marginRight: "15px" }}>
                            <select className="form-select propectstatus2" onChange={(e) => { myList(e.target.value, 1); setPropertyStatus(e.target.value) }} name="calender" id="calender">
                                <option value="">Property Status</option>
                                <option value="0">Draft</option>
                                {/* <option value="1">Active</option>
                                <option value="2">Active Contingent</option> */}
                                <option value="3">Exclusive</option>
                                <option value="5">Sold</option>
                                {/* <option value="4">Pending</option>
                                <option value="5">Sold</option>
                                <option value="6">Expired</option>
                                <option value="7">Cancelled</option>
                                <option value="8">Withdrawn</option>
                                <option value="10">Off market</option> */}
                            </select>
                        </div>
                        <div>
                            <Link to="/AddProperty" >
                                <Button className='addListbut mt-2'>Add Listing</Button>
                            </Link>
                        </div>
                        <div>
                            <img src={dataSort === -1 ? filterIcon : sortdown} alt="sortup.png" onClick={() => {
                                if (dataSort === 1) {
                                    setDataSort(-1);
                                } else {
                                    setDataSort(1);
                                }
                                myList(propertyStatus, currentPage)
                            }} className='filter-icon-img1' aria-hidden />
                        </div>
                    </div>
                </div>
                <Tabs defaultActiveKey="myList" id="uncontrolled-tab-example" className="mb-3" style={{ borderBottom: '1px solid lightgrey', background: 'transparent', paddingBottom: "20px" }}>
                    <Tab eventKey="myList" title="My Listing">
                        <div >
                            <div className='row mb-5 position-relative'>
                                <div className="col-md-8" style={{ height: "750px", overflow: "auto" }}>
                                    {loader ? array.map((item) => (<SkeletonHorizondalCard purpose={"myList"} />)) :
                                        myListData.map((data, i) =>
                                            <Row key={i} onClick={() => { handleClick(data, i); }} className='sellercardRow' style={{ border: (isActive && index === i) ? "1px solid rgb(4, 144, 251)" : "1px solid white" }}>
                                                <div className='col-lg-3'>
                                                    <img src={handleImage(data?.property !== undefined && data.property.thumbnail_image, data.property !== undefined && data.property.address)} alt="EditGroup1 " style={{ width: '100%', height: '180px', borderRadius: '10px', objectFit: "cover" }} {...(shouldUseCrossOrigin ? { crossOrigin: "anonymous" } : "")} />
                                                </div>
                                                <Col lg="3">
                                                    {data.property !== null && data.property !== undefined && data.property.owner_first_name !== undefined && data.property.owner_first_name !== null && data.property.owner_first_name !== "" &&
                                                        <div>
                                                            <h6>Name</h6>
                                                            <h6 className='sellername text-capitalize'>{(data.property !== undefined && data.property !== null && data.property.owner_first_name) + " " + (data.property !== undefined && data.property !== null && data.property.owner_last_name)
                                                            }</h6>
                                                        </div>}
                                                    <div>
                                                        <div>
                                                            <h6>Address</h6>
                                                            <h6 className='selleraddress'>{data.property !== undefined && data.property.address !== undefined && data.property.address.split(',').slice(0).shift()},</h6>
                                                            <h6 className='selleraddress'>{data.property !== undefined && data.property.address !== undefined && data.property.address.split(",").slice(1).join(",")}</h6>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-4'>
                                                                <h6>Sq.ft</h6>
                                                                <h6 className='selleraddress'>{formatPrice(data?.property?.square_feet?.$numberDecimal ? data.property.square_feet.$numberDecimal : "-")}</h6>
                                                            </div>
                                                            <div className='col-md-8'>
                                                                <h6>Beds/Baths</h6>
                                                                <h6 className='selleraddress'>{data?.property?.beds ? data.property.beds : ""} Beds, {data?.property?.baths ? data.property?.baths : ""} Baths</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg="3">
                                                    <h6>Property Status</h6>
                                                    {data?.property !== undefined &&
                                                        <div className='d-flex '>
                                                            <>{data.property.listing_status === 3 ?
                                                                < img src={data.property.companyID === "1" ? keywelogocard : data.property.companyID === "2" ? keywelogocard : data.property.companyID === "3" ? keywelogocard : data.property.companyID === "4" ? keywelogocard : data.property.companyID === "5" ? keywelogocard : keywelogocard} alt='newBrokaragelogo' style={{ width: "20px", height: "20px" }} /> :
                                                                <i className="fa fa-circle ClaimActiveIcon" style={{ color: getStatusColor(data.property.listing_status), marginTop: "0px" }} ></i>
                                                            }</>
                                                            <h6 className='propStatus' style={{ marginLeft: "10px" }}>{getListingStatus(data.property.listing_status)}</h6>
                                                        </div>}
                                                    <h6>Price</h6>
                                                    {data.property.listing_status == 5 ? <h6 className='sellerlistprice'>${formatPrice(data.property !== undefined && data.property !== null && data.property.sale_price !== null && data.property.sale_price !== undefined && data.property.sale_price.$numberDecimal == 0 ? data.property.sale_price.$numberDecimal : data.property !== undefined && data.property.sale_price !== null && data.property.sale_price !== undefined && data.property.sale_price.$numberDecimal)}</h6>
                                                        : <>
                                                            {(data?.property?.priceRangeMax?.$numberDecimal !== undefined && data?.property?.priceRangeMax?.$numberDecimal !== null && data?.property?.priceRangeMax?.$numberDecimal !== "0") ?
                                                                <h6 className='sellerlistprice' >${formatPrice(data.property.priceRangeMin.$numberDecimal)} - ${formatPrice(data.property.priceRangeMax.$numberDecimal)} </h6>
                                                                :
                                                                <h6 className='sellerlistprice'>${formatPrice(data.property !== undefined && data.property !== null && data.property.price !== null && data.property.price !== undefined && data.property.price.$numberDecimal == 0 ? data.property.price.$numberDecimal : data.property !== undefined && data.property.price !== null && data.property.price !== undefined && data.property.price.$numberDecimal)}</h6>
                                                            }
                                                        </>
                                                    }
                                                    <div>
                                                        {data.property.listing_status == 5 && <>
                                                            <h6>Sold on</h6>
                                                            <h6 className='sellerlistprice'>{data?.property?.coe_date ? Moment(data.property.coe_date).format("MM-DD-YYYY") : "-"}</h6></>
                                                        }</div>
                                                </Col>
                                                <Col lg="3">
                                                    <div>
                                                        {data.property.listing_status === 3 ? <img className='mlsimage' src={(data.property.brokerage_exclusive == true && data.property.companyID == "1") ? keywelogocard : keywelogocard} alt="mls2Image"></img> : <>{data.property.listing_status === 5 && <img className='mlsimage' src={mls2Image} alt="mls2Image"></img>}</>}
                                                    </div>
                                                    <div className='mt-20'>
                                                        <p className='mlstext'>Presented By {data?.property?.agent_id ? data.property.agent_id.name : data.property?.listing_agent_id ? data.property.listing_agent_id.name : data.property.listing_agent_fullname} of {data.property.agent_id ? (data.property.agent_id.brokerage_name !== null ? data.property.agent_id.brokerage_name : data.property.companyID == "1" ? "Colwell banker" : data.property.companyID == "2" ? "Keller williams" : data.property.companyID == "3" ? "Compass" : data.property.companyID == "4" ? "Intero Real Estate Services" : data.property.companyID == "5" ? "the agency" : "") : ((data.property.listing_agent_id !== undefined && data.property.listing_agent_id.office_name !== null && data.property.listing_agent_id !== undefined && data.property.listing_agent_id.office_name !== undefined) ? data.property.listing_agent_id.office_name : data.property.companyID == "1" ? "Colwell banker" : data.property.companyID == "2" ? "Keller williams" : data.property.companyID == "3" ? "Compass" : data.property.companyID == "4" ? "Intero Real Estate Services" : data.property.companyID == "5" ? "the agency" : "")}</p>
                                                    </div>
                                                </Col>
                                            </Row>)}
                                    <div className='resulttext'>
                                        {myListData.length == 0 && loader == false && <div className='no-resultSell mt-5'> Result not found</div>}
                                    </div>
                                </div>
                                {isMyList && myListDetails !== undefined && (
                                    <div className='col-md-4'>
                                        <MyListDetail data={myListDetails} reload={handleReload} anotherPros={OpnAnotherProps} offers={offer} propertyStatus={propertyStatus} currentPage={paginationCount} refresh={myList} recentSold={recentSold} verifyData={verifyData} close={() => setIsMyList(p => !p)} />
                                    </div>
                                )}
                            </div>
                            {(myListData.length !== 0) ? <Pagination pagecount={pagecount} handlePageClick={handlePageClick} forcepage={selectedPage} /> : null}
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </Layout>
    )
}
