import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import * as styled from "../../pages/buyers/buyer.module.css"
import * as styles from "../../pages/dashboard/dashboard.module.css"


const SkeletonHorizondalCard = (props) => {
  return (
    <>
      {props.purpose == "SavedSearch" &&
        <Row
          className={styled.cardRow}
        >
          <Col lg="2" ><Skeleton style={{ height: "20px", width: "120px"}} />
          <Skeleton style={{ height: "20px", width: "120px"}} />
          <Skeleton style={{ height: "20px", width: "120px"}} />
          <Skeleton style={{ height: "20px", width: "120px"}} /></Col>
          <Col lg="8" ><Skeleton /><Skeleton style={{ marginTop: "30px" }} /></Col>
          <Col lg="2" ><Skeleton style={{ height: "55px" }} /><Skeleton style={{ marginTop: "15px", height: "55px" }} /></Col> </Row>}


      {props.purpose == "tour" && <Row
        style={{
          borderRadius: "20px",
          boxShadow: "1px 2px 4px 2px #e4e5e7",
          margin: "30px 0 0 0",
          padding: "20px 10px",
          background: "#fff",

        }}>
        <Col lg="2" ><Skeleton style={{ borderRadius: "10px", height: "180px", width: "100%" }} /></Col>
        <Col lg="3" ><Skeleton style={{ marginTop: "48px" }} /><Skeleton style={{ marginTop: "48px" }} /></Col>
        <Col lg="2" ><Skeleton style={{ height: "150px", width: "150px", borderRadius: "50%" }} /></Col>
        <Col lg="3" ><Skeleton style={{ marginTop: "48px" }} /><Skeleton style={{ marginTop: "48px" }} /></Col>
        <Col lg="2" ><Skeleton style={{ marginTop: "25px", height: "55px" }} /><Skeleton style={{ marginTop: "15px", height: "55px" }} /></Col>
      </Row>}

      {props.purpose == "prospect" &&
        <Row
          className={styled.cardRow}
        >
          <Col lg="2" ><Skeleton style={{ height: "120px", width: "120px", borderRadius: "50%" }} /></Col>
          <Col lg="8" ><Skeleton /><Skeleton style={{ marginTop: "48px" }} /></Col>
          <Col lg="2" ><Skeleton style={{ height: "55px" }} /><Skeleton style={{ marginTop: "15px", height: "55px" }} /></Col> </Row>}

      {props.purpose == "myList" &&
        <Row
          className={styled.cardRow}
        >
          <Col lg="1" ><Skeleton style={{ height: "120px", width: "120px", borderRadius: "50%" }} /></Col> <Col lg="1" ></Col>
          <Col lg="10" ><Skeleton /><Skeleton style={{ marginTop: "48px" }} /></Col>
        </Row>}

      {props.purpose == "outBound" &&
        <Row
          className={styled.cardRow}
        ><Col lg="2" ><Skeleton style={{ height: "120px", width: "120px", borderRadius: "50%" }} /></Col>
          <Col lg="6" ><Skeleton /><Skeleton style={{ marginTop: "48px" }} /></Col>
          <Col lg="2" ><Skeleton style={{ height: "55px" }} /><Skeleton style={{ marginTop: "15px", height: "55px" }} /></Col>
          <Col lg="2" ><Skeleton style={{ height: "120px", width: "120px", borderRadius: "50%" }} /></Col>
        </Row>}

      {props.purpose == "agentCard" &&
        <Row className={styles.agentsCard}  >
          <Row className={styles.agentsCardsize}>
            <Col lg="2"><Skeleton style={{ height: "150px", width: "150px", borderRadius: "50%" }} /></Col>
            <Col lg="8"><Skeleton style={{ height: "20px", marginBottom: "25px" }} /><Skeleton style={{ height: "20px", width: "85%" }} /></Col>
            <Col lg="2"><Skeleton style={{ height: "50px" }} /></Col>
          </Row></Row>}

      {props.purpose == "network" && <Row>
        <Col lg='10'><Skeleton style={{ height: "21px", marginTop: "40px", marginBottom: "20px" }} /></Col>
        <Col lg='2'><Skeleton style={{ height: "21px", marginTop: "40px", marginBottom: "20px" }} /></Col>
      </Row>}
      {props.purpose == "networkdetail" && <Row

      >
        <Col lg="1"  ><Skeleton style={{ height: "90px", width: "90px", borderRadius: "50%" }} /></Col>
        <Col lg="11" style={{ paddingTop: "22px", paddingLeft: "78" }}><Skeleton /><Skeleton style={{ marginTop: "48px", width: "75%" }} /></Col>
      </Row>}

    </>
  )
}

export default SkeletonHorizondalCard