// extracted by mini-css-extract-plugin
export var ActiveShowing = "buyer-module--ActiveShowing--12993";
export var BuyerDetailSavedPropertyImage = "buyer-module--BuyerDetailSavedPropertyImage--6870a";
export var BuyerNameNickName = "buyer-module--BuyerNameNickName--0ab55";
export var BuyerProfileStatus = "buyer-module--BuyerProfileStatus--a212e";
export var BuyerToured = "buyer-module--BuyerToured--ea2a1";
export var DashboardActiveIcon = "buyer-module--DashboardActiveIcon--ae75e";
export var HeadContentAddress = "buyer-module--HeadContentAddress--04a38";
export var InterestedActiveIcon = "buyer-module--InterestedActiveIcon--8d9d0";
export var KeyweEstimateParent = "buyer-module--KeyweEstimateParent--d1012";
export var PhoneChat = "buyer-module--PhoneChat--96c42";
export var ProspectMessage = "buyer-module--ProspectMessage--83d21";
export var Recommendedproperty = "buyer-module--Recommendedproperty--71ea1";
export var TransferRequest = "buyer-module--TransferRequest--10180";
export var Viewbutton = "buyer-module--Viewbutton--2463d";
export var acceptBtn = "buyer-module--acceptBtn--1eb2c";
export var acceptButton = "buyer-module--acceptButton--92338";
export var acceptbuyerButton = "buyer-module--acceptbuyerButton--fe822";
export var activity = "buyer-module--activity--f72ad";
export var activity1 = "buyer-module--activity1--866a1";
export var activityparagraph = "buyer-module--activityparagraph--05f4c";
export var address = "buyer-module--address--540f9";
export var addresss = "buyer-module--addresss--51e3a";
export var arrow = "buyer-module--arrow--05109";
export var avatarimage = "buyer-module--avatarimage--cb990";
export var bedsValues1 = "buyer-module--bedsValues1--167eb";
export var bedsValues2 = "buyer-module--bedsValues2--38805";
export var buyerAreacontent = "buyer-module--buyerAreacontent--46a00";
export var buyerAvatarImage = "buyer-module--buyerAvatarImage--4ab29";
export var buyerDatecontent = "buyer-module--buyerDatecontent--652b9";
export var buyerDetailsImages = "buyer-module--buyerDetailsImages--eb368";
export var buyerDetailtoured = "buyer-module--buyerDetailtoured--c3138";
export var buyerDetailtouredcol0 = "buyer-module--buyerDetailtouredcol0--d4a4b";
export var buyerDetailtouredcol1 = "buyer-module--buyerDetailtouredcol1--9fc96";
export var buyerDetailtouredcol2 = "buyer-module--buyerDetailtouredcol2--d5a68";
export var buyerdetailedPrice0 = "buyer-module--buyerdetailedPrice0--88776";
export var buyerdetailedPrice1 = "buyer-module--buyerdetailedPrice1--6bf64";
export var callsName = "buyer-module--callsName--82b19";
export var cardRow = "buyer-module--cardRow--f4fec";
export var cardRowImg = "buyer-module--cardRowImg--a2ed7";
export var cardRowSpan = "buyer-module--cardRowSpan--cde9f";
export var cardtext2 = "buyer-module--cardtext2--bff3e";
export var carrieImage = "buyer-module--carrieImage--aa13c";
export var closebutton2 = "buyer-module--closebutton2--4658b";
export var dashboardActive = "buyer-module--dashboardActive--6ea75";
export var dashboardActive2 = "buyer-module--dashboardActive2--edff7";
export var dateoftour = "buyer-module--dateoftour--15d91";
export var declineBtn = "buyer-module--declineBtn--5e711";
export var declineBtn1 = "buyer-module--declineBtn1--9e01c";
export var declineButton = "buyer-module--declineButton--76821";
export var declinebuyerButton = "buyer-module--declinebuyerButton--1545e";
export var detCard = "buyer-module--detCard--ceab6";
export var detCard1 = "buyer-module--detCard1--34c26";
export var detCards = "buyer-module--detCards--a59eb";
export var detailback = "buyer-module--detailback--a5506";
export var dots2 = "buyer-module--dots2--2c9c1";
export var dottedimg = "buyer-module--dottedimg--c306d";
export var facircle = "buyer-module--facircle--a9efb";
export var headContent = "buyer-module--headContent--63f0a";
export var headContent1 = "buyer-module--headContent1--5f0f8";
export var headText = "buyer-module--headText--366c0";
export var homeType = "buyer-module--homeType--bdea9";
export var houseDetails = "buyer-module--houseDetails--0503e";
export var houseForSale = "buyer-module--houseForSale--b6dd4";
export var housedetail = "buyer-module--housedetail--2b518";
export var housesale = "buyer-module--housesale--723df";
export var hunterbg = "buyer-module--hunterbg--c79d2";
export var hunterbgName = "buyer-module--hunterbgName--4b317";
export var hunterbgName2 = "buyer-module--hunterbgName2--aaffb";
export var hunterimgName = "buyer-module--hunterimgName--dd611";
export var imagedetails = "buyer-module--imagedetails--643ef";
export var intaddess = "buyer-module--intaddess--4f693";
export var intaddess1 = "buyer-module--intaddess1--a9e7d";
export var intaddress = "buyer-module--intaddress--e9eec";
export var inthomeImage = "buyer-module--inthomeImage--05882";
export var listingdetail = "buyer-module--listingdetail--4ebf1";
export var manageoffer = "buyer-module--manageoffer--d1e4a";
export var mask192 = "buyer-module--mask192--0fa3e";
export var memberIcon = "buyer-module--memberIcon--398e3";
export var memberbut2 = "buyer-module--memberbut2--e1567";
export var memberdetails = "buyer-module--memberdetails--7c46d";
export var mlsagentCard = "buyer-module--mlsagentCard--98844";
export var msgint = "buyer-module--msgint--98d2c";
export var negotiation = "buyer-module--negotiation--c47bf";
export var negotiationimg = "buyer-module--negotiationimg--4d3eb";
export var notifybtn = "buyer-module--notifybtn--d4f67";
export var otherbut2 = "buyer-module--otherbut2--9480b";
export var phonintrest = "buyer-module--phonintrest--0ee7f";
export var port = "buyer-module--port--e96eb";
export var priceOfhouse = "buyer-module--priceOfhouse--1b4bd";
export var pricecard = "buyer-module--pricecard--10006";
export var pricehouse = "buyer-module--pricehouse--1ec5e";
export var pricehouse1 = "buyer-module--pricehouse1--a2613";
export var pricepop = "buyer-module--pricepop--b1db3";
export var profilestatusName = "buyer-module--profilestatusName--46787";
export var profilestatusNo = "buyer-module--profilestatusNo--4da70";
export var propCard = "buyer-module--propCard--47cab";
export var propImage = "buyer-module--propImage--11abc";
export var propback = "buyer-module--propback--90ccb";
export var prospName = "buyer-module--prospName--b9bac";
export var ptag = "buyer-module--ptag--1567b";
export var recenttour1 = "buyer-module--recenttour1--4734e";
export var recenttoursNum = "buyer-module--recenttoursNum--52435";
export var recenttoursText = "buyer-module--recenttoursText--e62c2";
export var sanfranImages = "buyer-module--sanfranImages--f75aa";
export var sanfranImg = "buyer-module--sanfranImg--7f8cf";
export var savedSale = "buyer-module--savedSale--e379b";
export var savedText = "buyer-module--savedText--c2a42";
export var searchCriet = "buyer-module--searchCriet--21f5b";
export var searchCriettext = "buyer-module--searchCriettext--7e3c3";
export var sellerAvatar = "buyer-module--sellerAvatar--768e0";
export var shareimage = "buyer-module--shareimage--81bd6";
export var simCard = "buyer-module--simCard--35d9c";
export var similar = "buyer-module--similar--44171";
export var similarCarouselstyle = "buyer-module--similarCarouselstyle--86fe5";
export var similarback = "buyer-module--similarback--8422f";
export var smallCard = "buyer-module--smallCard--f9301";
export var smallCard1 = "buyer-module--smallCard1--1ccd0";
export var tooltip = "buyer-module--tooltip--b2e04";
export var tooltiptext = "buyer-module--tooltiptext--20bc0";
export var tooltiptext1 = "buyer-module--tooltiptext1--fad7c";
export var tooltiptext2 = "buyer-module--tooltiptext2--7fb04";
export var totalcontent = "buyer-module--totalcontent--3dfd9";
export var totaltour = "buyer-module--totaltour--36ab3";
export var tourDate = "buyer-module--tourDate--4a5d3";
export var tourImagedetail = "buyer-module--tourImagedetail--f68db";
export var tourback = "buyer-module--tourback--a7111";
export var tourdetail = "buyer-module--tourdetail--376c8";
export var transferbut = "buyer-module--transferbut--ca5ac";
export var upcomingtour = "buyer-module--upcomingtour--fc941";
export var upload2 = "buyer-module--upload2--e0660";
export var viewbut2 = "buyer-module--viewbut2--7a890";
export var viewdetails = "buyer-module--viewdetails--e14a5";
export var viewdetailsBtn = "buyer-module--viewdetailsBtn--3b0a7";
export var viewdisbut = "buyer-module--viewdisbut--5220d";
export var viewerdetails = "buyer-module--viewerdetails--1fb5b";
export var viewtext1 = "buyer-module--viewtext1--dc44e";
export var viewtext2 = "buyer-module--viewtext2--cca64";
export var viewtext3 = "buyer-module--viewtext3--9a314";
export var week = "buyer-module--week--5b8b5";